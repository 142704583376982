$(() => {
    function calculatePercentage(element) {
        let $answerWrap = $(element).closest('.answer-wrap');
        let total = + $answerWrap.closest('.question-wrap').find('.question-total').val();
        let percentage = + $answerWrap.find('.response-percentage').val().trim('%');

        if (total && percentage) {
            let $responseCount = $answerWrap.find('.response-count');

            $responseCount.val(Math.round(total * percentage / 100));

            calculateNewTotal($responseCount);
        }
    }

    function calculateNewTotal(element) {
        let $responseCount = $(element);
        let responseCount = $responseCount.val();
        let newTotal = + responseCount;
        let $answerWrap = $responseCount.closest('.answer-wrap');
        let $newTotal = $answerWrap.find('.new-total');
        let $switch = $answerWrap.closest('form').find('#switch-add-replace');

        if (responseCount !== '') {
            if (! $switch.is(':checked')) {
                newTotal += + $answerWrap.find('.current-total').val();
            }

            $newTotal.val(newTotal);
        } else {
            $newTotal.val('');
        }
    }

    Livewire.hook('message.processed', (message) => {
        if (message.component.el.id === 'test-answers-form-wrap') {
            let $form = $(message.component.el);

            $form.find('.response-percentage').on('change', (element) => calculatePercentage(element.target));

            $form.find('.response-count').on('change', (element) => calculateNewTotal(element.target));

            $form.find('#switch-add-replace').on('change', () => {
                $form.find('.response-count').each((i, element) => calculateNewTotal(element));
            });

            $form.find('.question-total').on('change', (element) => {
                $(element.target).closest('.question-wrap')
                    .find('.response-percentage')
                    .each((i, element) => calculatePercentage(element));
            });
        }
    });
});
