$(() => {
    // Global
    let $flash = $('.flash-alert');

    $('[data-toggle="tooltip"]').tooltip();

    if ($flash.length) {
        window.addEventListener('flash', (event) => {
            $flash.addClass('alert-' + event.detail.type)
                .html(event.detail.message)
                .delay(500)
                .fadeIn(200)
                .delay(1000)
                .fadeOut(200)

            setTimeout(() => {
                $flash.removeClass('alert-' + event.detail.type).html('');
            }, 2000);
        });
    }

    // Top Bar
    let $body = $('body');

    livewire.on('currentViewUpdated', (currentView) => {
        $body.removeClass().addClass('theme-' + currentView.theme);
    });

    // Outcomes Reports
    $('#total-participants, #patients-per-week').off('change').on('change', function () {
        $('#potential-reach').val($('#total-participants').val() * $('#patients-per-week').val());
    });
});
