require('./bootstrap');

// Vendors
require('alpinejs');
require('jquery.easing');
require('chart.js/dist/chart.min');
require('startbootstrap-sb-admin-2/js/sb-admin-2.min');

// App
require('./main');
require('./readers/bulk-answers-form');
require('./charts-configuration');
