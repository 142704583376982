window.chartConfiguration = {
    colors: [
        '#1f77b4',
        '#aec7e8',
        '#ff7f0e',
        '#ffbb78',
        '#2ca02c',
        '#98df8a',
        '#d62728',
        '#ff9896',
        '#9467bd',
        '#c5b0d5',
        '#8c564b',
        '#c49c94',
        '#e377c2',
        '#f7b6d2',
        '#7f7f7f',
        '#c7c7c7',
        '#bcbd22',
        '#dbdb8d',
        '#17becf',
        '#9edae5'
    ],
    default() {
        return {
            type: '',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                plugins: {
                    title: {
                        align: 'start',
                        display: true,
                        font: {
                            size: 16,
                            weight: 'bold'
                        }
                    },
                    subtitle: {
                        align: 'start',
                        display: true,
                        font: {
                            size: 14,
                            style: 'italic',
                            weight: 'bold'
                        }
                    },
                    legend: {
                        labels: {
                            font: {
                                size: 14
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {}
                    }
                }
            }
        }
    },
    download(data, filename) {
        const link = document.createElement('a');

        link.href = data;
        link.download = filename;

        link.click();
        link.remove();
    },
    saveImage(canvas) {
        this.download(document.getElementById(canvas).toDataURL('image/png', 1), canvas + '.png');
    },
    saveData(canvas) {
        const chart = Chart.getChart(canvas);

        let csv = 'data:text/csv;charset=utf-8,';
        const length = chart.data.labels.length;
        const title = chart.options.plugins.title.text;
        const subtitle = chart.options.plugins.subtitle.text;

        const data = [
            chart.data.labels,
            ...chart.data.datasets.map((set) => set.data.splice(0,length))
        ];

        csv += (title) ? title + '\r\n' : '';
        csv += (subtitle) ? subtitle + '\r\n' : '';

        data.forEach(row => csv += row.join(',') + '\r\n');

        this.download(encodeURI(csv), canvas + '.csv');
    },
    lineChartDataset($i = 0) {
        return {
            fill: true,
            borderColor: chartConfiguration.colors[$i * 2],
            backgroundColor: chartConfiguration.colors[$i * 2] + '19',
            pointBackgroundColor: chartConfiguration.colors[$i * 2],
            tension: 0.3
        };
    }
};

chartConfiguration.bar = () => {
    let obj = chartConfiguration.default();

    obj.type = 'bar';
    obj.data.datasets[0] = {
        backgroundColor: (context) =>
            chartConfiguration.colors[context.dataIndex % chartConfiguration.colors.length]
    };

    obj.options.plugins.legend.display = false;
    obj.options.scales = {
        y: {
            beginAtZero: true,
        }
    };

    return obj;
}

chartConfiguration.stackedBar = () => {
    let obj = chartConfiguration.default();

    obj.type = 'bar';

    obj.options.plugins.tooltip.callbacks.label = function (context) {
        let hours = Math.floor(context.parsed.y / 3600);
        let minutes = Math.floor(context.parsed.y % 3600 / 60);
        let seconds = Math.floor(context.parsed.y % 3600 % 60)

        return `${context.label}: ${hours} h, ${minutes} m, ${seconds} s`;
    };

    obj.options.scales = {
        x: {
            stacked: true,
        },
        y: {
            ticks: {
                callback: function(value) {
                    return Math.round(value / 900) / 4 + ' h';
                }
            },
            minValue: 3600,
            stacked: true,
            beginAtZero: true,
        }
    };

    return obj;
}

chartConfiguration.pie = () => {
    let obj = chartConfiguration.default();

    obj.type = 'pie';
    obj.data.datasets[0] = {
        backgroundColor: (context) =>
          chartConfiguration.colors[context.dataIndex % chartConfiguration.colors.length]
    };

    obj.options.plugins.legend.align = 'start';
    obj.options.plugins.tooltip.callbacks.label = function (context) {
        let total = context.dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
        let percentage = Math.round((context.parsed / total) * 10000) / 100;

        return `${context.label}: ${context.parsed} (${percentage}%)`;
    };

    return obj;
}

chartConfiguration.line = () => {
    let obj = chartConfiguration.default();

    obj.type = 'line';
    obj.data.datasets[0] = chartConfiguration.lineChartDataset();

    return obj;
}

chartConfiguration.area = () => {
    let obj = chartConfiguration.default();

    obj.type = 'line';
    obj.options.scales = {
        y: {
            stacked: true,
        }
    }

    return obj;
}
